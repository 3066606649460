define('components/lighttable/single-map',['require','leaflet','class','leafletMinimap'],function (require) {
  "use strict";

  var L = require("leaflet"),
    Class = require("class");
    require("leafletMinimap");

  /**
   * @class SingleMap
   */
  var SingleMap = Class.extend({
    initialize: function(options) {
      this.mapConfig = options.mapConfig;
      window.map = this.map = L.map(options.mapConfig.el, {
            attributionControl: false,
            zoomControl:false,
            maxBounds: [[-300, -215.0511],[300, 215.0511]]
          }).setView([-133.638, 10.055], 1);
      this.baseLayer = L.tileLayer(this.mapConfig.host + "lighttable_" + this.options.quarteroId +  "/{z}/{x}/{y}.png",
        {
          subdomains: this.mapConfig.subdomains,
          minZoom: 1,
          maxZoom: 5,
          noWrap: true
        }).addTo(this.map);

      // minimap
      var miniMapLayer = new L.TileLayer(this.mapConfig.host + "lighttable_" + this.options.quarteroId +  "/{z}/{x}/{y}.png",
        {
          subdomains: this.mapConfig.subdomains,
          minZoom: 1,
          maxZoom: 1,
          // tileSize: 250,
          noWrap: true
        });
      //console.log(this.map.getBounds());
      this.miniMap = new L.Control.MiniMap(miniMapLayer,{width: 230, toggleDisplay: true, zoomLevelFixed: 1 }).addTo(this.map);
      new L.Control.Zoom({ position: "bottomright" }).addTo(this.map);
    },
    reload: function(quarteroId){
      this.options.quarteroId = quarteroId;
      this.map.remove();
      this.initialize(this.options);
    }
  });

  return SingleMap;
});
