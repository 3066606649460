define('components/map/city-map',['require','leaflet','./quarterons-layer','./pois-layer','./data-layer','./data-label','jquery','components/bus','class','Proj4Leaflet'],function (require) {
  "use strict";

  var L = require("leaflet"),
    QuarteronsLayer = require("./quarterons-layer"),
    POIsLayer = require("./pois-layer"),
    DataLayer = require("./data-layer"),
    DataLabel = require("./data-label"),
    // appConfig = require("components/app-config"),
    $ = require("jquery"),
    Bus = require("components/bus"),
    Class = require("class");
    require("Proj4Leaflet");

    L.Icon.Default.imagePath = "images";
    
    var streetIcon = new L.Icon({
        iconUrl: "images/marker-icon-street.png",
        iconRetinaUrl: "images/marker-icon-street-2x.png",
        shadowUrl: "images/marker-shadow.png",
        shadowRetinaUrl: "images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        shadowSize: [41, 41]
    });

  /**
   * @class POIsLayer
   */
  var CityMap = Class.extend({
    initialize: function(options) {
      this.mapConfig = options.mapConfig;
      this.baseLayer = null;
      this.streetsLayer = null;
      this.map = null;
      this.quarteronsLayer = null;
      this.POIsLayer = {};
      this.dataLayer = null;
      this.dataLayers = {};
      this.dataLabels = {};
      this.start();
      Bus.commands.setHandler("zoomToStreet", this._zoomToStreet, this);
      Bus.commands.setHandler("removeSearchMarker", this._removeSearchMarker, this);
      // streetsLayer: Migrated to data-layer.js
      // Bus.commands.setHandler("showStreetsLayer", this.showStreetsLayer, this);
      // Bus.commands.setHandler("hideStreetsLayer", this.hideStreetsLayer, this);
    },
    start: function() {
      // Create the map with the initial options
      var map = this.map = L.map(this.mapConfig.el, {
          attributionControl: false,
          zoomControl:false,
          maxBounds: L.latLngBounds(this.mapConfig.bounds)
        }),
        zoomClasses = [];
      
        // Popup behaviours
        map.on("popupopen", function(e) {
          var px = map.project(e.popup._latlng); // find the pixel location on the map where the popup anchor is
          px.y -= e.popup._container.clientHeight/2 + 200; // find the height of the popup container, divide by 2, subtract from the Y axis of marker location
          map.panTo(map.unproject(px),{animate: true}); // pan to new center
        });
      
      new L.Control.Zoom({ position: "bottomright" }).addTo(this.map);
      // Map Zoom 
      for (var i = this.mapConfig.minZoom; i <= this.mapConfig.maxZoom; i+=1) {
        zoomClasses.push("zoom-" + i);
      }

      this.map.on("zoomend", function() {
        var $map = $(map.getContainer());
        $map.removeClass(zoomClasses.join(" "));
        $map.addClass("zoom-" + map.getZoom());
      });

      this.map.setView(this.mapConfig.centerCoords, this.mapConfig.startZoom);

      // this.baseLayer = L.tileLayer(this.mapConfig.baseLayerURL, {
      //   subdomains: this.mapConfig.subdomains,
      //   minZoom: this.mapConfig.minZoom,
      //   maxZoom: this.mapConfig.maxZoom
      // }).addTo(this.map);

      // Catografic server tiles:
      this.crs25831 = new L.Proj.CRS("EPSG:25831","+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
          // {
          //   resolutions: [1100, 550, 275, 100, 50, 25, 10, 5, 2, 1, 0.5, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.10, 0.10]
          // }
      );
      this.baseLayer = L.tileLayer.wms("http://mapcache.icc.cat/map/bases/service?", {
                        layers: "orto",
                        format: "image/jpeg",
                        crs: this.crs25831,
                        continuousWorld: true,
                        minZoom: this.mapConfig.minZoom,
                        maxZoom: this.mapConfig.maxZoom,
                        attribution: "Institut Cartogràfic i Geològic de Catalunya -ICGC",
                  }).addTo(this.map);

      //  this.streetsLayer = L.tileLayer(this.mapConfig.streetsLayerURL, {
      //   subdomains: this.mapConfig.subdomains,
      //   minZoom: this.mapConfig.minZoom,
      //   maxZoom: this.mapConfig.maxZoom
      // }).addTo(this.map);

      this._initLayers();

      return this;
    },
    showDataLayer: function(layer) {
      if(this.dataLayers[layer]) {
        this.dataLayers[layer].show();
      } else {
        this.dataLayers[layer] = new DataLayer({
          layerId: layer,
          map: this.map,
          mapConfig: this.mapConfig
        });
      }
      if(layer==6 || layer == 7){
        if(this.dataLabels[layer]) {
          this.dataLabels[layer].show();
        } else{
          this.dataLabels[layer] = new DataLabel({
            labelId: layer,
            map: this.map,
            mapConfig: this.mapConfig
          });
        }
      }
    },
    hideDataLayer: function(layer) {
      if(this.dataLayers[layer]) {
        this.dataLayers[layer].hide();
      }
      if(layer==6 || layer == 7){
        this.dataLabels[layer].hide();
      }
    },
    showStreetsLayer: function(){
      this.streetsLayer = L.tileLayer(this.mapConfig.streetsLayerURL, {
        subdomains: this.mapConfig.subdomains,
        minZoom: this.mapConfig.minZoom,
        maxZoom: this.mapConfig.maxZoom
      }).addTo(this.map);
    },
    hideStreetsLayer: function() {
      this.map.removeLayer(this.streetsLayer);
    },
    showPOIs: function(layerId) {
      if(this.POIsLayer[layerId]){
	this.POIsLayer[layerId].show();  
      }
      else{
        this.POIsLayer[layerId] = new POIsLayer({map: this.map, mapConfig: this.mapConfig, layerId: layerId});
      }
    },
    hidePOIs: function(layerId) {
      if(this.POIsLayer[layerId]){
	this.POIsLayer[layerId].hide(); 
      }  
    },
    showQuarterons: function() {
      this.quarteronsLayer.show();
    },
    hideQuarterons: function() {
      this.quarteronsLayer.hide();
    },
    setQuarteronsOpacity: function(opacity) {
      this.quarteronsLayer.setOpacity(opacity);
    },
    setQuarteronsMode: function(mode, quarteroId) {
      //console.log("[CityMap] Set quarterons layer into mode %s .", mode);
      //console.log("city-map setQuarteronsMode quarteroId: "+quarteroId);
      this.quarteronsLayer.setMode(mode, quarteroId);
    },
    _initLayers: function() {
      this.quarteronsLayer = new QuarteronsLayer({map: this.map, mapConfig: this.mapConfig});

      this.quarteronsLayer.on("selectedQuaretro:change", function(quartero) {
        this.trigger("selectedQuaretro:change", quartero);
      }, this);
    },
    testQuartero: function(quartero){
        this.quarteronsLayer.testQuartero(quartero);
    },
    _zoomToStreet: function(coords){
      var self = this;
      // this.searchMarker = L.featureGroup(L.marker([coords[1],coords[0]], {icon: streetIcon})).addTo(this.map);
      this.searchMarker = L.marker([coords[1],coords[0]], {icon: streetIcon}).addTo(this.map);
      this.map.setZoom(15,{animate:true});
      setTimeout(function() {         
        self.map.setView(new L.LatLng(coords[1],coords[0]),18,{animate:true}); 
      }, 1500);
    },
    _removeSearchMarker: function(){
      if(this.searchMarker){
        this.map.removeLayer(this.searchMarker);
      }
    }
  });

  return CityMap;
});
