define('modules/map/controller',['require','marionette','components/bus','./views/layout'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    Bus = require("components/bus"),
    LayoutView = require("./views/layout");

  /**
   * @class MapController
   */
  var MapController = Marionette.Controller.extend({
    initialize: function (options) {
      this.app = options.app;
    },
    selectQuartero: function() {
      //console.log("MapController selectQuartero");
      Bus.commands.execute("enableQuarteronsSelection");
    },
    showQuartero: function(quarteroId) {
      //console.log("Showing quartero %d", quarteroId);
      Bus.commands.execute("selectQuartero", quarteroId);
    },
    show: function() {
      //console.log("MapController show");
      // Render initial state
      this.options.regionContainer.show(new LayoutView());
    },
    index : function () {
      //console.log("MapController index");
    }
  });

  return MapController;
});
