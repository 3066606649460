define('modules/story/controller',['require','marionette','jquery','./views/layout'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    $ = require("jquery"),
    LayoutView = require("./views/layout");

  /**
   * @class StorytellingController
   */
  var StoryController = Marionette.Controller.extend({
    initialize: function (options) {
      this.app = options.app;
    },
    index : function () {
      //console.log("index");
    },
    show: function(chapter, slide) {
      var self = this;
      console.log("show chapter: %s slide: %s", chapter, slide);
      if(!self.data){
        //console.log("getting index data");
        $.get("data/stories/stories.json").done(function(data) {
          self.data = data;
          self._showChapter(chapter, slide);
        });
      }else{
        this._showChapter(chapter, slide);
      }
    },
    _showChapter: function(chapter, slide){
        if(chapter){
          if(!slide){
            console.log("showChapter slide undefined");
            slide = 1;
          }
          if(!this.view){
            this.view = new LayoutView({stories: this.data, chapter: chapter, slide: slide});
            this.options.regionContainer.show(this.view);
          }else{
            this.view._slideGoTo({chapter: chapter, slide: slide});
          }
        }else{
          console.log("showChapter not shown");
        }
    }
  });

  return StoryController;
});
