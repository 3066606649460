define('app',['require','backbone','marionette','components/app-config','jquery','components/bus','autoload/vendors','autoload/plugins','modules/home/module','modules/map/module','modules/lighttable/module','modules/poiContent/module','modules/storytelling/module','modules/story/module','modules/credits/module'],function (require) {
  "use strict";

  // TODO: For mobile devices
  //window.$("body").css("height", screen.height);

  var Backbone = require("backbone"),
    Marionette = require("marionette"),
    appConfig = require("components/app-config"),
    $ = require("jquery"),
    Bus = require("components/bus");

  var app = window.app = new Marionette.Application();

  // Initialize configuration
  app.addInitializer(function (options) {
    appConfig.init(options);
    Bus.init(app);
  });

  // jquery browser fallback
  $.browser = {msie: false};
  
  // Startup
  app.addInitializer(function () {
    require("autoload/vendors")(app);
    require("autoload/plugins")(app);

    app.addRegions({
      "mapRegion" : "#map",
      "homeRegion" : "#home",
      "storyTellingRegion" : "#storytelling",
      "lightTableRegion" : "#lighttable",
      "poiRegion": "#poiContent",
      "creditsRegion": "#creditsContent",
      "storyRegion": "#story"
    });

    // Initialize modules each one in its containing region
    require("modules/home/module")(app, app.homeRegion);
    require("modules/map/module")(app, app.mapRegion);
    require("modules/lighttable/module")(app, app.lightTableRegion);
    require("modules/poiContent/module")(app, app.poiRegion);
    require("modules/storytelling/module")(app, app.storyTellingRegion);
    require("modules/story/module")(app, app.storyRegion);
    require("modules/credits/module")(app, app.creditsRegion);

    this.controller = new AppController({app: app, $appContainer: window.$("body")});

    if (!Backbone.history.start({ pushState : appConfig.pushState })) {
      Backbone.history.navigate("", { trigger : true });
    }
  });

  /**
   * Global Application controller to deal with the module management
   */
  var AppController = Marionette.Controller.extend({
    showSectionCallbacks: {
      "home": "showHome",
      "map": "showMap",
      "lighttable": "showLighttable",
      "storytelling": "showStoryTelling",
      "story": "showStory",
      "poiContent": "showPoiContent",
      "creditsContent": "showCreditsContent"
    },
    initialize: function (options) {
      this.app = options.app;
      this.$appContainer = options.$appContainer;
      Bus.vent.on("routedSection", this.routedSection, this);
    },
    routedSection: function(section) {
      this[this.showSectionCallbacks[section]].call(this);
    },
    // Callbacks to show the different modules, one at a time
    showHome : function () {
      $(".intro_search").hide();
      this.$appContainer.removeClass("home-module-inactive")
        .removeClass("map-module-active lighttable-module-active storytelling-module-active poiContent-module-active creditsContent-module-active");
      this.app.module("home").start();
      Bus.vent.trigger("homeVideo:start");
    },
    showMap: function() {
      this.$appContainer.addClass("home-module-inactive");
      this.$appContainer.addClass("map-module-active storytelling-module-active")
        .removeClass("home-module-active lighttable-module-active poiContent-module-active creditsContent-module-active");
      // Clean story
      // var slider = $("#full-width-slider").data("royalSlider");  
      // this.app.module("story").stop();
      // console.log("story stop");
      this.app.module("map").start();
      // $("#home").css("display","none");
      // Story telling module shows on top of map module
      this.app.module("storytelling").start();
      Bus.vent.trigger("homeVideo:stop");
    },
    showLighttable: function() {
      $(".intro_search").hide();
      this.$appContainer.addClass("lighttable-module-active")
        .removeClass("home-module-active map-module-active storytelling-module-active poiContent-module-active creditsContent-module-active");
      this.app.module("lighttable").start();
    },
    showStoryTelling: function() {
      this.$appContainer.addClass("storytelling-module-active map-module-active")
        .removeClass("home-module-active lighttable-module-active poiContent-module-active creditsContent-module-active");
      $("#storytelling").css("position","inherit");
      this.app.module("storytelling").start();
      // Map module shows behind the storytelling module
      this.app.module("map").start();
    },
    showStory: function() {
      this.$appContainer.addClass("storytelling-module-active story-module-active map-module-active")
        .removeClass("home-module-active lighttable-module-active poiContent-module-active creditsContent-module-active");
      $("#story").css("position","inherit");
      this.app.module("story").start();
      this.app.module("storytelling").start();
      // Map module shows behind the storytelling module
      this.app.module("map").start();
    },
    showPoiContent: function() {
      //console.log("APPPOI");
       this.$appContainer.addClass("poiContent-module-active")
        .removeClass("home-module-active creditsContent-module-active");
        //$("body").css("position","relative");
        // $("#map").css("position","absolute");
        // $("#storytelling").css("position","absolute");
        // $("#map>div>div>div").removeAttr("style");
	this.app.module("poiContent").start();
        this.app.module("storytelling").start();
    },
    showCreditsContent: function() {
      //console.log("Credits");
       this.$appContainer.addClass("creditsContent-module-active")
        .removeClass("home-module-active poiContent-module-active");
        //$("body").css("position","relative");
        // $("#map").css("position","absolute");
        $("#storytelling").css("position","absolute");
        // $("#map>div>div>div").removeAttr("style");
        this.app.module("creditsContent").start();
    }
  });

  return app;
});
