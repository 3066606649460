define('modules/lighttable/controller',['require','marionette','jquery','underscore','./views/index'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    $ = require("jquery"),
    _ = require("underscore"),
    IndexView = require("./views/index");

  var downloadRefPrefix = "11334_5D33_11458_";
  var downloadPath = "download/";
  /**
   * @class LighttableController
   */
  var LighttableController = Marionette.Controller.extend({
    initialize: function (options) {
      this.app = options.app;
    },
    start: function(metadata){
      this.data = metadata;
      if(this.lightview && this.lightview.clicked){
        this.lightview.setQuartero(this.quarteroId);
      }else {
        //console.log("new view");
        this.lightview = new IndexView({quarteroId: this.quarteroId, metadata: metadata});
        this.options.regionContainer.show(this.lightview);        
      }
    },
    show: function(quarteroId) {
      this.quarteroId = quarteroId;
      if(!this.data){
        $.when(this._loadMetadata()).done(_.bind(this.start, this));
      }else{
        this.start(this.data);
      }
    },
    index : function () {
    },
    _loadMetadata: function(){
      var deferred = new $.Deferred();
      // self = this;
      $.get("data/quarterons-metadata.json").done(function(data) {
        var i = 0;
        while(i < data.length){
          // download/11334_5D33_11458_97/11334_5D33_11458_097.jpg
          if(!data[i].fake){
            data[i].download = downloadPath+downloadRefPrefix+data[i].id+"/"+downloadRefPrefix+"0"+data[i].id+".jpg";
            data[i].mini = "images/lighttable/"+downloadRefPrefix+"0"+data[i].id+".png";
          }
          i += 1;
        }
        deferred.resolve(data);
      });
      return deferred.promise();
    }
  });

  return LighttableController;
});
