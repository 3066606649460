define('modules/storytelling/controller',['require','marionette','jquery','./views/layout'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    $ = require("jquery"),
    LayoutView = require("./views/layout");

  /**
   * @class StorytellingController
   */
  var StorytellingController = Marionette.Controller.extend({
    initialize: function (options) {
      this.app = options.app;
    },
    index : function () {
      //console.log("index");
    },
    show: function() {
      //console.log("show id: %s , slide: %s", id, slide);
      var self = this;
      // Index synchronous load
      if(!self.indexData){
        //console.log("getting index data");
        $.get("data/stories/index.json").done(function(data) {
          self.indexData = data;
          self._showBar();
        });
      }else{
        self._showBar();
      }
    },
    _showBar: function() {
      //console.log("showBar");
      this.cookie = this._getCookie();
      this.options.regionContainer.show(new LayoutView({status: this.cookie, index: this.indexData}));
    },
    _getCookie: function(){
      //console.log("_getCookie");
      var name = "darreramirada=";
      var ca = document.cookie.split(";");
      for(var i=0; i<ca.length; i+=1) {
          var c = ca[i];
          while (c.charAt(0)==" "){
            c = c.substring(1);
          } 
          if (c.indexOf(name) === 0){
            return JSON.parse(c.substring(name.length,c.length));
          }
      }
      return this.indexData;
    }
  });

  return StorytellingController;
});
