define('modules/poiContent/module',['require','./controller','./router','components/bus'],function (require) {
  "use strict";

  var Controller = require("./controller"),
    Router = require("./router"),
    Bus = require("components/bus");

  /**
   * @class POIContentModule
   */
  var POIContentModule = function (app, regionContainer) {
    app.module("poiContent", function () {
      this.startWithParent = false;

      this.controller = new Controller({
        app: app,
        regionContainer: regionContainer
      });

      var router = this.router = new Router({ controller : this.controller });
      Bus.commands.setHandler("showPOIContent", function(poiId) {
	router.navigate("poi/" + poiId, {trigger: true});
      });
    });
  };

  return POIContentModule;
});
