define('modules/map/views/poi',['require','marionette','components/bus','text!./../templates/poi.hbs'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
   Bus = require("components/bus");

  /**
   * @class POIView
   */
  var POIView = Marionette.ItemView.extend({
    template: require("text!./../templates/poi.hbs"),
    events:{
      "click img.poi-cover": "_showPoiContent"
    },
    serializeData: function() {
      return this.options.data;
    },
    _showPoiContent: function(){
      Bus.commands.execute("showPOIContent", this.options.data.id);
    }

  });

  return POIView;
});
