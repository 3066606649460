define('modules/map/router',['require','marionette','components/bus'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    Bus = require("components/bus");

  /**
   * @class MapRouter
   */
  var MapRouter = Marionette.AppRouter.extend({
    appRoutes: {
      "mapa": "index",
      "mapa/quartero": "selectQuartero",
      "mapa/quartero/:id": "showQuartero"
    },
    onRoute: function() {
      // Tell the orchestrating app that the home module has processed the current
      // route, so it should be displayed
      //console.log("MapRouter onRoute");
      Bus.vent.trigger("routedSection", "map");
    }
  });

  return MapRouter;
});
