define('modules/map/views/layout',['require','marionette','./map','./filters','components/bus','text!./../templates/layout.hbs'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    MapView = require("./map"),
    FiltersView = require("./filters"),
    Bus = require("components/bus");

  /**
   * @class MapLayout
   */
  var MapLayout = Marionette.LayoutView.extend({
    className: "map-module",
    template: require("text!./../templates/layout.hbs"),
    regions: {
      "mapContainerRegion": ".map-container",
      "mapFiltersRegion": ".map-filters-container"
    },
    ui: {
      "$toggle": ".toggle-streets-age",
      "$input": "#tstreets"
    },
    events: {
      "click #streets-age": "_toggleSelector",
      "click #tstreets": "_toggleStreets",
      "click #actuals": "_setActuals",
      "click #1860": "_setOld"
    },
    initialize: function(options) {
      this.app = options.app;
    },
    onRender: function() {
      this.mapContainerRegion.show(new MapView({app: this.app}));
      this.mapFiltersRegion.show(new FiltersView({app: this.app}));
    },
    _setActuals: function(){
      this.ui.$input.parent().find("label").html("Carrers actuals");
      this.ui.$toggle.removeClass("active");
      this.ui.$toggle.find("#actuals").removeClass("inactive");
      this.ui.$toggle.find("#1860").addClass("inactive");
      if(this.ui.$input.prop("checked")){
        // Capa carrers antics
        Bus.commands.execute("hideDataLayer","8");
        // Capa actual
        Bus.commands.execute("showDataLayer","9");
      } 
    },
    _setOld: function(){
      this.ui.$input.parent().find("label").html("Carrers del 1860");
      this.ui.$toggle.removeClass("active");
      this.ui.$toggle.find("#1860").removeClass("inactive");
      this.ui.$toggle.find("#actuals").addClass("inactive");
      if(this.ui.$input.prop("checked")){
        // Capa actual
        Bus.commands.execute("hideDataLayer","9");
        // Capa carrers antics
        Bus.commands.execute("showDataLayer","8");
      } 
    },
    _toggleSelector: function(){
      //console.log("layout _toggleSelector");
      this.ui.$toggle.toggleClass("active");
    },
    _toggleStreets: function(){
      //console.log("_toggleStreets checked? ", this.ui.$input.prop("checked"));
      if (this.ui.$input.prop("checked")) {
        var inactive = this.ui.$toggle.find(".inactive").attr("id");
        //console.log("toggleStreets id to be ignored: ", inactive);
        if(inactive == "1860"){
          // Mostrem capa carrers actuals
          //console.log("show data layer 9");
          Bus.commands.execute("showDataLayer","9");
        }else{
          // Mostrem capa carrers antics
          Bus.commands.execute("showDataLayer","8");
        }        
      }else {
        // Capa carrers nous
        Bus.commands.execute("hideDataLayer","9");
        // Capa carrers antics
        Bus.commands.execute("hideDataLayer","8");
      }
    }
  });

  return MapLayout;
});
