require(["app"], function (app) {
    "use strict";

    /**
     * Start the application
     */
    app.start(window.application);
});
define("bootstrap", function(){});

