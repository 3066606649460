define('modules/credits/controller',['require','marionette','./views/credits'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    CreditsView = require("./views/credits");

  /**
   * @class CreditsController
   */
  var CreditsController = Marionette.Controller.extend({
    initialize: function (options) {
      this.app = options.app;
    },
    show: function() {//poName parameter
      this.options.regionContainer.show(new CreditsView({}));
    },
    index : function () {
    }
  });

  return CreditsController;
});
