define('modules/poiContent/controller',['require','marionette','jquery','underscore','./views/poiContent','components/app-config'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    $ = require("jquery"),
    _ = require("underscore"),
    POIView = require("./views/poiContent"),
    appConfig = require("components/app-config");

  /**
   * @class POIConetntController
   */
  var POIConetntController = Marionette.Controller.extend({
    initialize: function (options) {
      this.app = options.app;   
    },
    start: function(POIData) {
      this._POIData = POIData;
      this.options.regionContainer.show(new POIView({poi: POIData}));
    },
    show: function(poiId) {//poName parameter
      //console.log("SHOW POI");
      var poiData = poiId.split("-");
      this.poiId = poiId;
      this.poiLayer = poiData[1];    
      // TODO: maybe this should be provided? not this class responsibility
      $.when(this._loadPOIs()).done(_.bind(this.start, this));
    },
    index : function () {
    },
    _loadPOIs: function() {
      var deferred = new $.Deferred(),
        self = this;
      $.get(appConfig.mapDefaults.POIsPath.replace("pois-{id}", "pois-"+this.poiLayer)).done(function(data) { 
        var i = 0;
        while(i < data.length){
          if (data[i].id == self.poiId){
            self.data = data[i];
            break;
          }
          i += 1;
        }
        deferred.resolve(self.data);
      });
      return deferred.promise();
    }
  });

  return POIConetntController;
});
