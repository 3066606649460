define('components/app-config',['require','underscore'],function(require) {
  "use strict";

  var _ = require("underscore");

  function AppConfig() {}

  AppConfig.prototype.init = function(options) {
    _.extend(this, options);
    this.initMapDefaults();
  };

  AppConfig.prototype.initMapDefaults = function() {
    this.mapDefaults.host = window.location.protocol + "//" + this.mapDefaults.host + "/";
    this.mapDefaults.baseLayerURL = this.mapDefaults.host + this.mapDefaults.baseLayer;
    this.mapDefaults.streetsLayerURL = this.mapDefaults.host + this.mapDefaults.streetsLayer;
    this.mapDefaults.oldStreetsLayerURL = this.mapDefaults.host + this.mapDefaults.oldStreetsLayer;
    // this.mapDefaults.POIsPath = this.paths.staticFiles + "/" + this.mapDefaults.POIsPath;
    // this.mapDefaults.quarteronsPath = this.paths.staticFiles + "/" + this.mapDefaults.quarteronsPath;
    // this.mapDefaults.layersPath = this.paths.staticFiles + "/" + this.mapDefaults.layersPath;
    
    this.mapDefaults.POIsPath = this.mapDefaults.POIsPath;
    this.mapDefaults.quarteronsPath = this.mapDefaults.quarteronsPath;
    this.mapDefaults.layersPath = this.mapDefaults.layersPath;
  };

  AppConfig.prototype.staticFile = function(path) {
    var basepath = "";
    if(this.paths.staticFiles){
      basepath = this.paths.staticFiles;
    }
    return basepath + "/" + path;
  };

  AppConfig.prototype.imagePath = function(image) {
    return this.staticFile("images/" + image);
  };

  return new AppConfig();
});
