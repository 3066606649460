define('modules/home/views/index',['require','marionette','jquery','components/bus','jquery-ui','jquery-imageloader','videojs','bigVideo','text!./../templates/index.hbs'],function (require) {
    "use strict";
      
    var Marionette = require("marionette"),
    $ = require("jquery"),
    // _ = require("underscore"),
    Bus = require("components/bus");
    require("jquery-ui");
    require("jquery-imageloader");
    require("videojs");
    require("bigVideo");

    // Helper
    function adjustImagePositioning(bigImg) {
        //console.log("adjustImagePositioning");
        bigImg.each(function(){
            var $img = $(this),
                img = new Image();

            img.src = $img.attr("src");

            var windowWidth = $(window).width(),
                windowHeight = $(window).height(),
                r_w = windowHeight / windowWidth,
                i_w = img.width,
                i_h = img.height,
                r_i = i_h / i_w,
                new_w, new_h; // new_left, new_top;

            if( r_w > r_i ) {
                new_h   = windowHeight;
                new_w   = windowHeight / r_i;
            }
            else {
                new_h   = windowWidth * r_i;
                new_w   = windowWidth;
            }

            $img.css({
                width   : new_w,
                height  : new_h,
                left    : ( windowWidth - new_w ) / 2,
                top     : ( windowHeight - new_h ) / 2
            });

        });

    }

    var IndexView = Marionette.ItemView.extend({
        className: "home-module",
        template : require("text!./../templates/index.hbs"),
        ui:{
            "$poster": ".video-poster",
            "$video": ".video-background",
            "$image": ".home-big-image",
        },
        events: {
            "click .button": "_goToMap"
        },
        onShow: function(){
            // this._startVideo();
            //console.log("onShow");
        },
        serializeData: function(){
            //videos/1-placapi-poster.png
            this.vids = [
                "videos/1-placapi-ok.mp4",
                "videos/2-macba-ok.mp4",
                "videos/3-placareial-ok.mp4"
            ];
            this.vids.sort(function() { return 0.5 - Math.random(); }); // random order on load
            var poster = this.vids[0].replace("-ok.mp4", "-poster.png");
            return { poster: poster };
        },
        onRender: function(){
            // var $window = $(window);
            // var width = $window.width();
            // var height = $window.height();
            //console.log("home onRender");

            this._initVideo();
            
            Bus.vent.on("homeVideo:stop", this._stopVideo, this);
            Bus.vent.on("homeVideo:start", this._startVideo, this);
        },
        _initVideo: function(){
            //console.log("home _initVideo");
            var self = this;

            var $bigImage = this.ui.$image;
            this.ui.$poster.imageloader(
              {
                selector: ".home-big-image",
                callback: function () {
                  $bigImage.css("position","relative");
                  adjustImagePositioning($bigImage);
                }
              }
            );

            //console.log("video-background:",$(".video-background"));
            self.BV = new $.BigVideo({
                container: self.ui.$video,
                controls: false,
                useFlashForFirefox: false
            });

            setTimeout(function(){
                //console.log("showPlaylist");
                self.BV.init();
                self.player = self.BV.getPlayer();
                self.BV.showPlaylist(self.vids,{ambient:true});  //
                self.player.on("loadeddata", function(e){
                    console.log("video loaded: ", e);
                    self.ui.$video.show();
                    self.ui.$poster.hide();
                });
            }, 1000);

        },
        _stopVideo: function(){
            if(this.BV){
                this.BV.dispose();
            }
        },
        _startVideo: function(){
            //console.log("_startVideo");
            this._initVideo();
        },
        _goToMap: function(){
            //console.log("_goToMap");
            // $(".chapter-navigation").tooltip();
            window.location = "#mapa";
        }
    });

    return IndexView;
});
