define('modules/lighttable/router',['require','marionette','components/bus'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    Bus = require("components/bus");

  /**
   * @class LighttableRouter
   */
  var LighttableRouter = Marionette.AppRouter.extend({
    appRoutes: {
      "taula": "index",
      "taula/:id": "show"
    },
    onRoute: function() {
      // Tell the orchestrating app that the home module has processed the current
      // route, so it should be displayed
      Bus.vent.trigger("routedSection", "lighttable");
    }
  });

  return LighttableRouter;
});
