define('modules/storytelling/module',['require','./controller'],function (require) {
  "use strict";

  var Controller = require("./controller");

  /**
   * @class StorytellingModule
   */
  var StorytellingModule =  function (app, regionContainer) {
    app.module("storytelling", function (Module) {
      this.startWithParent = false;

      this.controller = new Controller({
        app: app,
        regionContainer: regionContainer
      });

      Module.addInitializer(function() {
        this.controller.show();
      });
    });
  };

  return StorytellingModule;
});
