define('modules/home/controller',['require','marionette','./views/index'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    IndexView = require("./views/index");

  /**
   * @class  HomeController
   */
  var HomeController = Marionette.Controller.extend({
    initialize: function (options) {
      this.app = options.app;
    },
    show: function() {
      this.options.regionContainer.show(new IndexView());
    },
    index : function () {
    }
  });

  return HomeController;
});
