define('modules/story/module',['require','./controller','./router'],function (require) {
  "use strict";

  var Controller = require("./controller"),
    Router = require("./router");

  /**
   * @class StoryModule
   */
  var StoryModule =  function (app, regionContainer) {
    app.module("story", function (Module) {
      this.startWithParent = false;

      this.controller = new Controller({
        app: app,
        regionContainer: regionContainer
      });

      Module.addInitializer(function() {
        this.controller.show();
      });

      this.router = new Router({ controller : this.controller });
    });
  };

  return StoryModule;
});
