define('modules/home/router',['require','marionette','components/bus'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    Bus = require("components/bus");

  /**
   * @class HomeRouter
   */
  var HomeRouter = Marionette.AppRouter.extend({
    appRoutes: {
      "": "index"
    },
    onRoute: function() {
      // Tell the orchestrating app that the home module has processed the current
      // route, so it should be displayed
      Bus.vent.trigger("routedSection", "home");
    }
  });

  return HomeRouter;
});
