define('components/map/quarterons-layer',['require','leaflet','jquery','underscore','class'],function (require) {
  "use strict";

  var L = require("leaflet"),
    $ = require("jquery"),
    _ = require("underscore"),
    Class = require("class");

  var doublequarterons = ["25","28","29","45","61"];

  // Helper method
  var getCentroid = function (arr) {
    var result = _.reduce(arr, function (accum, point) {
      return [accum[0] + point[0], accum[1] + point[1]];
    }, [0,0]);

    return [result[0]/arr.length, result[1]/arr.length];
  };

  /**
   * @class QuarteronsLayer
   */
  var QuarteronsLayer = Class.extend({
    initialize: function(options) {
      this.map = options.map;
      this.mapConfig = options.mapConfig;
      this.opacity = 1;
      // TODO: maybe this should be provided? not this class responsibility
      $.when(this._loadQuarteronsCoords()).done(_.bind(this.start, this));
    },
    start: function(selectionData) {
      this._selectionData = selectionData;
      this.setMode(this.mapConfig.mode, this.mapConfig.selectedQuartero);
    },
    setMode: function(mode, quartero) {
      this.hide();
      this.currentMode = mode;
      this.currentSelection = quartero;
      //console.log("setMode: selectionData",this._selectionData);
      //console.log("quarterons-layer.setMode.quartero", quartero);
      //console.log("quarterons-layer.setMode.mode", mode);

      if(this.currentMode === "browse") {
        this._initLayerGroup();
        this.trigger("selectedQuaretro:change", null);
      } else if(this.currentMode === "select") {
        this._initLayerGroup();
        this._createSelectionLayer();
        this.trigger("selectedQuaretro:change", null);
      } else if(this.currentMode === "selected") {
        this._createCuarteroLayer(quartero);
        this.trigger("selectedQuaretro:change", quartero);
      } else if(this.currentMode == "requested") {
        var center = this.getQuarteroCenter(quartero);
        if(doublequarterons.indexOf(quartero) > -1){
          // two values
          quartero = [{id: quartero, file: quartero+"A"},{id: quartero, file: quartero+"B"}];
        }else{
          quartero = {id: quartero, file: quartero};
        }
        this._createCuarteroLayer(quartero);
        if(center){
          //console.log("center:"+center);
          this.map.panTo(center);
        }
        this.trigger("selectedQuaretro:change", quartero);
      }
      return this;
    },
    getQuarteroCenter: function(quartero){
      //console.log("quartero requested:", quartero);
      //console.log("this.data:", this.data);
      var center = null;
      for(var i=0; i < this.data.features.length; i+=1){
        if(this.data.features[i].properties.id == parseInt(quartero)){
          center = getCentroid(this.data.features[i].geometry.coordinates[0][0]);
          break;
        }
      }
      return [center[1], center[0]];
    },
    hide: function() {
      if(this.selectionLayer) {
        this.map.removeLayer(this.selectionLayer);
        this.map.removeLayer(this.markerGroup);
      }
      if(this.selectedLayer) {
        this.map.removeLayer(this.selectedLayer);
      }
      if(this.selectedLayer2) {
        this.map.removeLayer(this.selectedLayer2);
      }
      if(this.layerGroup) {
        this.map.removeLayer(this.layerGroup);
      }
    },
    show: function() {
      this.setMode("browse", null);
    },
    setOpacity: function(opacity) {
      this.opacity = opacity;
      if(this.selectedLayer) {
        this.selectedLayer.setOpacity(opacity);
      }
      if(this.layerGroup) {
        this.layerGroup.eachLayer(function(layer) {
          layer.setOpacity(opacity);
        });
      }
    },
    _createCuarteroLayer: function(quartero) {
      var quarteroStr = "";
      var tileURL = "";

      if(quartero instanceof Array){
        quarteroStr = "quartero_" + quartero[0].file;
        tileURL = this.mapConfig.host + quarteroStr + "/{z}/{x}/{y}.png";

        this.selectedLayer = L.tileLayer(tileURL, {
          subdomains: this.mapConfig.subdomains,
          minZoom: this.mapConfig.minZoom,
          maxZoom: this.mapConfig.maxZoom,
          opacity: this.opacity
        }).addTo(this.map);

        quarteroStr = "quartero_" + quartero[1].file;
        tileURL = this.mapConfig.host + quarteroStr + "/{z}/{x}/{y}.png";

        this.selectedLayer2 = L.tileLayer(tileURL, {
          subdomains: this.mapConfig.subdomains,
          minZoom: this.mapConfig.minZoom,
          maxZoom: this.mapConfig.maxZoom,
          opacity: this.opacity
        }).addTo(this.map);

      }else{
        quarteroStr = "quartero_" + quartero.file;
        tileURL = this.mapConfig.host + quarteroStr + "/{z}/{x}/{y}.png";

        this.selectedLayer = L.tileLayer(tileURL, {
          subdomains: this.mapConfig.subdomains,
          minZoom: this.mapConfig.minZoom,
          maxZoom: this.mapConfig.maxZoom,
          opacity: this.opacity
        }).addTo(this.map);
      }
      
    },
    _initLayerGroup: function() {
      var layers = [];
      for (var i = 0; i < this.mapConfig.quarterons.length; i+=1) {
        var file = this.mapConfig.quarterons[i].file,
          tileURL = this.mapConfig.host + file + "/{z}/{x}/{y}.png";
        layers.push(L.tileLayer(tileURL, {
          subdomains: this.mapConfig.subdomains,
          minZoom: this.mapConfig.minZoom,
          maxZoom: this.mapConfig.maxZoom,
          zIndex: this.mapConfig.quarterons[i].zIndex,
          opacity: this.opacity
        }));
      }

      this.layerGroup = L.layerGroup(layers).addTo(this.map);

      return this;
    },
    _createSelectionLayer: function() {
      var self = this,
        map = this.map;

      var markers = [];
      this.selectionLayer = L.geoJson(this._selectionData, {
        style: {"className": "quartero-outline"},
        onEachFeature: function(feature, layer) {

          if(feature.geometry.coordinates[0].length) {
            var coords = feature.geometry.coordinates[0][0],
              centroid = getCentroid(coords);
              markers.push(L.marker([
                  centroid[1],
                  centroid[0]
              ], {
                  icon: L.divIcon({
                    className: "quartero-id",
                    html: feature.properties.id
                  }),
                  file: feature.properties.file
              }));
          }

          //assign polygon id so we can reference it later
          layer._leaflet_id = "quartero_"+feature.properties.file;

          layer.on("mouseover", function(e) {
            $(e.layer._path).attr("class", "quartero-outline quartero-outline-hover");
          });
          layer.on("mouseout", function(e) {
            $(e.layer._path).attr("class", "quartero-outline");
          });
          layer.on("click", function(e) {
            map.fitBounds(e.layer.getBounds());
            // self.setMode("selected", {id: feature.properties.id, file: feature.properties.file});
            self.setMode("selected", {id: feature.properties.id, file: feature.properties.file});
          });
        }
      }).addTo(map);

      if(markers.length) {
        this.markerGroup = L.featureGroup(markers).addTo(this.map);
      }
    },
    _loadQuarteronsCoords: function() {
      var deferred = new $.Deferred(),
        self = this;

      $.get(this.mapConfig.quarteronsPath).done(function(data) {
        self.data = data;
        deferred.resolve(data);
      });

      return deferred.promise();
    }
  });

  return QuarteronsLayer;
});
