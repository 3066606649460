define('components/map/data-label',['require','leaflet','jquery','underscore','class','leaflet.zoomcss'],function (require) {
  "use strict";

  var L = require("leaflet"),
    $ = require("jquery"),
    _ = require("underscore"),
    Class = require("class");
    require("leaflet.zoomcss");

  /**
   * @class DataLayer
   */
  var DataLabel = Class.extend({
    initialize: function() {
      this.map = this.options.map;
      this.mapConfig = this.options.mapConfig;
      this.labelId = this.options.labelId;
      //console.log(this.options.labelId);
      this.markersGroup = null;
      // TODO: maybe this should be provided? not this class responsibility
      $.when(this._loadLabel()).done(_.bind(this.start, this));
    },
    start: function(labelData) {
      this._labelData = labelData;
      this.show();
    },
    show: function() {
      var markers = [],
      className = "";
      _.each(this._labelData, function(label) {
        if (label.overlap){
          className = "data-label-" + this.labelId + " overlap";
        } else {
          className = "data-label-" + this.labelId;
        }
        var marker = L.marker([label.geometry.coordinates[0][1],label.geometry.coordinates[0][0]], {opacity: 0.01}).bindLabel(label.properties.TEXTSTRING, { noHide: true, className: className, offset: [-40, -10]});
        markers.push(marker);
      },this);
      this.markersGroup = L.featureGroup(markers).addTo(this.map);
    },
    hide: function() {
      this.map.removeLayer(this.markersGroup);
    },
    _loadLabel: function() {
      var deferred = new $.Deferred(),
        labelURL = this.mapConfig.layersPath.replace("layer-{id}", "label-" + this.labelId),
        self = this;
      $.get(labelURL).done(function(data) {
        self.data = data;
        deferred.resolve(data);
      });
      return deferred.promise();
    }
  });

  return DataLabel;
});
