define('modules/poiContent/views/poiContent',['require','marionette','components/app-config','underscore','bxslider','text!./../templates/poiContent.hbs'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    appConfig = require("components/app-config"),
    _ = require("underscore");
    require("bxslider");
    // require("bjqs");
  
  return Marionette.ItemView.extend({
    className: "poiContent-module",
    template: require("text!./../templates/poiContent.hbs"),
    ui: {
      "$mapContainer": ".map-container",
      "$slider":".bxslider",
      "$next":"#slider-next",
      "$prev":"#slider-prev",
      "$animation": "#primary",
      "$button_animation": "#button_animation",
      "$loading": ".loading"
    },
    events: {
      "click #button_animation" : "startAnimation",
      "click #slider-next" : "_next",
      "click #slider-prev" : "_prev",
      "click .back": "closePoiContent"
    },
    serializeData: function() {
      return this.options.poi;
    },
    onShow: function() {
      // reset slider
      if(this.slider){
        this.ui.$slider.destroySlider();
      }
    },
    closePoiContent: function(){
      if(this.slider){
        this.ui.$slider.destroySlider();
      }
      window.location = "#mapa";
    },
    onRender: function() {
      var self = this;
      //console.log("onRender");
      this.mapConfig = _.extend({el: self.ui.$mapContainer[0]}, appConfig.mapDefaults);
      this.slider = this.ui.$slider.bxSlider({
        mode: "fade",
        // adaptiveHeight: true,
        minSlides: 1,
        maxSlides: 1,
        useCSS: false,
        pager: false,
        infiniteLoop: false,
        controls: false,
        speed: 0,
        startSlide: 0,
        responsive: false,
        onSliderLoad: function(){
          setTimeout(function(){
            self.ui.$loading.addClass("hide");
            self.ui.$slider.find("img").css("opacity", 1);
          },500);
        }
      });
      // this.slider = this.ui.$slider.bjqs({
      //   animtype: "fade",
      //   animduration: 0,
      //   responsive: true,
      //   showcontrols : false
      // });
      setTimeout(function(){
        self.ui.$slider.show();
      }, 1000);

      //console.log(this.slider);
      // total Count
      this.count = this.ui.$slider.getSlideCount();
      //console.log("current slide:", this.slider.getCurrentSlide());
      //console.log("slider count:", this.count);
      this._testLimits();
    },
    startAnimation: function(){
      var self = this;
      this.ui.$button_animation.children("img").hide();

      if( this.ui.$animation.css("display") == "none" ){
        this.ui.$animation.fadeIn(5000, function(){
          //console.log("fadeIn done");
          setTimeout(function() {
            self.ui.$button_animation.children("img").show();
          }, 1000);
        });
      }else{
        this.ui.$animation.fadeOut(5000, function(){
          //console.log("fadeOut done");
          setTimeout(function() {
            self.ui.$button_animation.children("img").show();
          }, 1000);
        });
      }
    },
    _next: function(evt){
      //console.log("_next");
      var $el = this.$(evt.currentTarget);
      if(!$el.hasClass("disabled")){
        //console.log("_next go");
        this.ui.$slider.goToNextSlide();
      }
      this._testLimits();
      return false;
    },
    _prev: function(evt){
      //console.log("_prev");
      var $el = this.$(evt.currentTarget);
      if(!$el.hasClass("disabled")){
        //console.log("_prev go");
        this.ui.$slider.goToPrevSlide();
      }
      this._testLimits();
      return false;
    },
    _testLimits: function(){
      // down limit
      if(this.ui.$slider.getCurrentSlide() <= 0){
        //console.log("down limit");
        this.ui.$prev.addClass("disabled");
      }else{
        this.ui.$prev.removeClass("disabled");
      }
      // up limit
      if(this.ui.$slider.getCurrentSlide() >= (this.count - 1)){
        //console.log("up limit");
        this.ui.$next.addClass("disabled");
      }else{
        this.ui.$next.removeClass("disabled");
      }  
    }
  });
});
