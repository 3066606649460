define('class',['require','underscore','backbone'],function (require) {
  "use strict";

  var _ = require("underscore"),
    Backbone = require("backbone");


  /**
   * Base Class declaration used to start all class hierarchies.
   * Based on Backbone extension method.
   *
   * @class Class
   */
  var Class = function(options) {
    this.options = options;
    this.initialize.apply(this, arguments);
  };
  Class.prototype.initialize = function() {};
  Class.extend = Backbone.Model.extend;
  _.extend(Class.prototype, Backbone.Events);

  return Class;
});
