define('components/bus',[],function() {
  "use strict";
  var instance = null;

  /**
   * Singleton Class used to share a common Bus from any app Class without
   * needing a reference to the App instance
   */
  function Bus(){
  }

  Bus.prototype = {
    init: function(app){
      this.vent = app.vent;
      this.commands = app.commands;
      this.reqres = app.reqres;
    }
  };

  Bus.getInstance = function(){
    if(instance === null){
        instance = new Bus();
    }
    return instance;
  };

  return Bus.getInstance();
});

