define('modules/story/views/layout',['require','marionette','jquery','components/bus','jquery-ui','modernizr','royalslider','text!./../templates/layout.hbs'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
  $ = require("jquery"),
  Bus = require("components/bus");
  require("jquery-ui");
  require("modernizr");
  require("royalslider");
  
  return Marionette.ItemView.extend({
    className: "story-module",
    template: require("text!./../templates/layout.hbs"),
    ui: {
        "$slider": "#full-width-slider",
        "$close": ".backMap"
    },
    events: {
    	  "click .backMap": "goToMap",
        "click #play": "playCurrentVideo",
        "click .leftOption": "_goTo",
        "click .rightOption": "_goTo",
        "click .mes": "showDescription",
        "mouseenter .show-image-footer": "showImageFooter",
        "mouseleave .show-image-footer": "hideImageFooter"
    },
    initialize: function(){
      Bus.vent.on("slideGoTo", this._slideGoTo, this);
      Bus.vent.on("closeStory", this.closeStory, this);
    },
    serializeData: function(){
      this.notouch = true;
      this.startSlide = this.getSlideNumber(this.options.chapter, this.options.slide);
      // Modernizr
      if(window.Modernizr.touch){
        this.notouch = false;
        console.log("serializeData touch");
      }else{
        console.log("serializeData notouch");
      }
      // 
      // console.log("serializeData stories:", this.options.stories);
      // Adding notouch info
      if(this.notouch){
        for (var i=0; i<this.options.stories.length; i+=1) {
          this.options.stories[i]["notouch"] = "true";
        }        
      }
      return { stories: this.options.stories, chapter: this.options.chapter };
    },
    onShow: function() {
        //console.log("navigation bar onShow");
    }, 
    onRender: function(){
        console.log("onRender");
        // if(!this.slider){
        var self = this;
          console.log("initializing story");
          var width = parseInt(window.innerWidth);
          var header = $("#brand").height() + $("#brand-cultura").height();
          // console.log("putoheader height", header);
          // console.log("window height", parseInt(window.innerHeight));
          var height = parseInt(window.innerHeight) - header + 2;
          // console.log("width", width);
          // console.log("height", height);
          setTimeout(function(){
              $("#full-width-slider").royalSlider({
                arrowsNav: true,
                loop: false,
                keyboardNavEnabled: true,
                controlsInside: false,
                imageScaleMode: "fill",
                imageAlignCenter:true,
                arrowsNavAutoHide: false,
                autoScaleSlider: true, 
                autoScaleSliderWidth: width,     
                autoScaleSliderHeight: height,
                controlNavigation: "none",
                thumbsFitInViewport: false,
                navigateByClick: false,
                startSlideId: self.startSlide,
                autoPlay: false,
                transitionType:"move",
                globalCaption: false,
                thumbs: {
                  autoCenter: false,
                  fitInViewport: true,
                  orientation: "vertical",
                  spacing: 0,
                  paddingBottom: 0
                },
                video: {
                  autoHideArrows:false,
                  autoHideControlNav:false,
                  autoHideBlocks: true
                }
              });
              Bus.vent.trigger("slideChange", {chapter:self.options.chapter, slide:self.options.slide});
          }, 500);

          setTimeout(function(){
              self.slider = $("#full-width-slider").data("royalSlider");
              self.ui.$slider.addClass("active");
              var status = self.getChapterSlide(self.slider.currSlideId);
              if(status.slide === 1 && status.chapter !== 0 && status.chapter !== 8){
                self.autoplayVideo(status.chapter);
              }
              // Player
              self.slider.ev.on("rsOnCreateVideoElement", function(e, url) {
                    // url - path to video from data-rsVideo attribute
                    // slider.videoObj - jQuery object that holds video HTML code
                    // slider.videoObj must be IFRAME, VIDEO or EMBED element, or have class rsVideoObj
                    if(url.indexOf("vimeo") < 0){
                      self.slider.videoObj = $("<video id=\"video_"+self.slider.currSlideId+"\" class=\"rsVideoObj\" width=\"auto\" height=\"90%\" controls><source src=\""+url+"\" type=\"video/mp4\" />Your browser does not support the video tag.</video>");
                      setTimeout(function(){
                        $("#video_"+self.slider.currSlideId).get(0).play();
                      }, 50);
                    }
              });
              // Slide Change
              self.slider.ev.on("rsAfterSlideChange", function(event) {
                    // triggers after slide change
                    console.log("event :", event);
                    console.log("current slide:", self.slider.currSlideId);
                    console.log("current stat", status);
                    status = self.getChapterSlide(self.slider.currSlideId);
                    Bus.vent.trigger("slideChange", status);
                    if(status.slide === 1 && status.chapter !== 0 && status.chapter !== 8){
                      self.autoplayVideo(status.chapter);
                    }
              });
              self.ui.$close.css("opacity", 1);
          }, 1000);

    },
    _slideGoTo: function(data){
      var newSlide = this.getSlideNumber(data.chapter, data.slide);
      var self = this;
      if(this.slider){
        // this.ui.$slider.removeClass("active");
        self.slider.goTo(newSlide);
        self.ui.$slider.addClass("active");
        self.ui.$close.css("opacity", 1);
      }
    },
    getSlideNumber: function(chapter, slide){
      // Total
      for (var i=0; i<this.options.stories.length; i+=1) {
          if(this.options.stories[i].capitol == chapter){
            if(this.options.stories[i].diapo == slide - 1){
              return i;
            }
          }
      }
      return 1;
    },
    getChapterSlide: function(slide){
      var story = this.options.stories[slide];
      console.log("story: ", story);
      return {chapter: story.capitol, slide: parseInt(story.diapo) + 1};
    },
    showDescription: function(evt){
      var $el = this.$(evt.currentTarget);
      $el.parent().find(".descripcio").toggleClass("active");
      // console.log($el.parent().find(".descripcio"));
      if($el.parent().find(".descripcio").hasClass("active")){
        $el.find("img").attr("src","images/minus_dark.svg");
      }else{
        $el.find("img").attr("src","images/plus_dark.svg");
      }
    },
    showImageFooter: function(evt){
      console.log("showImageFooter");
      var $el = this.$(evt.currentTarget);
      $el.parent().find(".image-footer-content").toggle(true);
      // console.log($el.parent().find(".image-footer-content"));
    },
    hideImageFooter: function(evt){
      console.log("hideImageFooter");
      var $el = this.$(evt.currentTarget);
      $el.parent().find(".image-footer-content").toggle(false);
      // console.log($el.parent().find(".image-footer-content"));
    },
    _goTo: function(evt){
        var $el = this.$(evt.currentTarget);
        window.location = $el.find("a").attr("alt");
    },
    _goToChapter: function(evt){
        var $el = this.$(evt.currentTarget);
        // alert($el.data("href"));
        window.location = $el.data("href");
    },
    goToMap: function(){
        this.stopCurrentVideo();
        this.ui.$close.css("opacity", 0);
        this.ui.$slider.removeClass("active");
        Bus.vent.trigger("slideClosed", true);
        window.location = "#mapa";
    },
    autoplayVideo: function(chapter){
        console.log("autoplayVideo");
        var $video = $("#autoplay_"+chapter).get(0);
        if($video){
            $video.play();
            $("#poster_"+chapter).hide();
            // this.ui.$play_button.hide();
            // this.ui.$content_text.hide();
        }
        // this.ui.$index.hide();
    },
    closeStory: function(){
      console.log("closeStory");
      this.stopCurrentVideo();
      this.ui.$close.css("opacity", 0);
      this.ui.$slider.removeClass("active");
      Bus.vent.trigger("slideClosed", true);
    },
    stopCurrentVideo: function(){
      try{
        // Vimeo player
        // Needs vimeo library (included in index.html) : http://a.vimeocdn.com/js/froogaloop2.min.js
        var iframe = $(".rsVideoActive").find("iframe").get(0);
        var $player = $f(iframe);
        if($player){
          $player.api("pause");
          console.log("vimeo player paused");
        }
      }catch(err){
        console.log(err.message);
      }
    }
  });
});
