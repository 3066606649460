define('modules/lighttable/module',['require','./controller','./router','components/bus'],function (require) {
  "use strict";

  var Controller = require("./controller"),
    Router = require("./router"),
    Bus = require("components/bus");

  /**
   * @class LighttableModule
   */
  var LighttableModule = function (app, regionContainer) {
    app.module("lighttable", function () {
      this.startWithParent = false;

      this.controller = new Controller({
        app: app,
        regionContainer: regionContainer
      });

      var router = this.router = new Router({ controller : this.controller });
      Bus.commands.setHandler("showLighttable", function(quarteroId) {
        router.navigate("taula/" + quarteroId, {trigger: true});
      });

    });
  };

  return LighttableModule;
});
