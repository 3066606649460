define('modules/storytelling/views/layout',['require','marionette','jquery','components/bus','jquery-ui','modernizr','text!./../templates/layout.hbs'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
  $ = require("jquery"),
  Bus = require("components/bus");
  require("jquery-ui");
  require("modernizr");
  
  return Marionette.ItemView.extend({
    className: "storytelling-module",
    template: require("text!./../templates/layout.hbs"),
    ui: {
    	"$index": ".chapter-navigation",
	// Social network menu
	"$button_social_network": ".social-network-button",
	"$layers_social_network": ".layer-social-network",
    },
    events: {
        "click td:not(.social-network-button)": "_goToChapter",
	// Social network options
	"click .social-network-button": "_toggleSocialNetwork"
    },
    initialize: function(){
        Bus.vent.on("slideChange", this._slideChange, this);
        Bus.vent.on("slideClosed", this._resetActive, this);
    },
    onShow: function() {
        //console.log("navigation bar onShow");
    }, 
    onRender: function(){
        console.log("onRender");
        this.cookie = this.options.status;
        // Tooltip
        this.ui.$index.tooltip({
            position: {
                // my: "left bottom",
                // at: "left top-10",
                my: "center bottom",
                at: "center top-10",
                collision: "none",
                using: function( position, feedback ) {
                    $( this ).css( position );
                    $( "<div>" )
                        .addClass( "arrow" )
                        .addClass( feedback.vertical )
                        .addClass( feedback.horizontal )
                        .appendTo( this );
                }
            },
            content: function () {
                return this.getAttribute("title");
            }
        });
        // Progress
        this.renderProgress();
    },
    _goToChapter: function(evt){
        console.log("go to Chapter");
        var $el = this.$(evt.currentTarget);
        this.ui.$index.find("td").removeClass("active");
        $el.addClass("active");
        var link = $el.data("href");
        if(link.indexOf("credits") > 0){
            Bus.vent.trigger("closeStory");
            window.location = $el.data("href");
            return true;
        }
        var chapter = parseInt(link.split("/")[1]);
        var slide = 1;
        if(this.cookie[chapter]){

            if(this.cookie[chapter].slide !== undefined){
                slide = this.cookie[chapter].slide;
            }    
            if(slide >= this.cookie[chapter].total){
                slide = 1;
                this.cookie[chapter].slide = 1;
                this._setCookie();
            }
            if(window.location.href.indexOf("#historia") > 0){
                Bus.vent.trigger("slideGoTo", {chapter: chapter, slide: slide});
                console.log("trigger historia");
            }else{
                window.location = $el.data("href")+"/"+slide;
            }
        }
	// Close social network layer
	this.ui.$button_social_network.removeClass("active"); 
	this.ui.$layers_social_network.hide();  
        // }
    },
    _toggleSocialNetwork: function(evt){
      var current = window.location.href;
      for (var i=0; i<this.cookie.length; i+=1) {
	var els = this.ui.$layers_social_network.find("a");
	
        if(this.cookie[i].active && !(window.location.href.indexOf("#mapa") >= 0)){
	    var url = window.location.href.substr(0, window.location.href.indexOf("#"));
	    for (var j=0; j<els.length; j+=1) {
		$(els[j]).attr("href", $(els[j]).data("href")+url+((url.substr(-1) !== "/") ? "/" : "")+"%23historia/"+i+"/"+this.cookie[i].slide);
	    }
	    break;
        }
	else{
	    for (var k=0; k<els.length; k+=1) {
		$(els[k]).attr("href", $(els[k]).data("href")+window.location.href);
	    }
	}
      }
      var $el = this.$(evt.currentTarget);
      $el.toggleClass("active");
      if($el.hasClass("active")) {
        this.ui.$button_social_network.addClass("active"); 
	this.ui.$layers_social_network.show();
      } else {
        this.ui.$button_social_network.removeClass("active"); 
	this.ui.$layers_social_network.hide();  
      }
    },
    renderProgress: function() {
        //console.log("renderProgress: ", this.cookie);
        var els = this.ui.$index.find("table").find("td:not(.social-network-button)");
        for (var i=0; i<els.length-1; i+=1) {
            if (i>0 && i<els.length-2){
                var percentage = 0;
                if(this.cookie[i].slide){
                    percentage = (this.cookie[i].slide/this.cookie[i].total)*100;
                    this._renderGradient(els[i], percentage, this.cookie[i].active);
                }
            }else {
                if(this.cookie[i].slide && this.cookie[i].slide === 1){
                    $(els[i]).addClass("visited");
                }else {
                    $(els[i]).removeClass("visited");
                }
            }
        }
    },
    _resetActive: function(){
        this.ui.$index.find("td").removeClass("active");
        this.renderProgress();
    },
    _slideChange: function(data){
        console.log("_slideChange:", data);
        this._setCookie(data.chapter, data.slide);
        this.ui.$index.find("td").removeClass("active");
        this.ui.$index.find("#c"+data.chapter).addClass("active");
        this.renderProgress();
    },
    _renderGradient: function(el, percentage, active){
        console.log("_renderGradient");
	// Close social network element
	this._toggleSocialNetwork(el);
        var p1 = percentage - 3;
        var p2 = p1 + 3;
        var color = "#666666";
        var marker = "#ff0000";
        if($(el).hasClass("active")){
            color = "#999999";
        }
        if(!active){
            marker = color;
        }
        el.style.background = "-webkit-gradient(linear, left top, right top, color-stop("+p1+"%,"+color+"), color-stop("+p2+"%,"+marker+"), color-stop("+p2+"%,#333333))";
        el.style.background = "-moz-linear-gradient(left center, "+color+" "+p1+"%, "+marker+" "+p2+"%, #333333 "+p2+"%)";
        // el.style.background = "rgba(0, 0, 0, 0) -moz-linear-gradient(left center, "+color+" "+p1+"%, "+marker+" "+p2+"%, #333333 "+p2+"%)  repeat scroll 0 0;";
        el.style.background = "-o-linear-gradient(left, "+color+" "+p1+"%, "+marker+" "+p2+"%, #333333 "+p2+"%);";
        el.style.background = "linear-gradient(to right, "+color+" "+p1+"%, "+marker+" "+p2+"%, #333333 "+p2+"%);";
    },
    _setCookie: function(chapter, slide){
      //console.log("_setCookie :", this.cookie);
      for (var i=0; i<this.cookie.length; i+=1) {
        if(this.cookie[i].id == chapter){
            this.cookie[i].slide = parseInt(slide);
            this.cookie[i].active = true;
        }else{
            this.cookie[i].active = false;
        }
      }
      document.cookie = "darreramirada=" + JSON.stringify(this.cookie) + "; ";
    }
  });
});
