define('modules/credits/module',['require','./controller','./router','components/bus'],function (require) {
  "use strict";

  var Controller = require("./controller"),
    Router = require("./router"),
    Bus = require("components/bus");

  /**
   * @class CreditsModule
   */
  var CreditsModule = function (app, regionContainer) {
    app.module("creditsontent", function () {
      this.startWithParent = false;

      this.controller = new Controller({
        app: app,
        regionContainer: regionContainer
      });

      var router = this.router = new Router({ controller : this.controller });
      Bus.commands.setHandler("showCreditsContent", function() {
        router.navigate("credits/", {trigger: true});
      });
    });
  };

  return CreditsModule;
});
