define('modules/home/module',['require','./controller','./router'],function (require) {
  "use strict";

  var Controller = require("./controller"),
    Router = require("./router");

  /**
   * @class HomeModule
   */
  var HomeModule = function (app, regionContainer) {
    app.module("home", function (HomeModule) {
      this.startWithParent = false;

      this.controller = new Controller({
        app: app,
        regionContainer: regionContainer
      });

      this.router = new Router({ controller : this.controller });

      HomeModule.addInitializer(function() {
        this.controller.show();
      });

    });
  };

  return HomeModule;
});
