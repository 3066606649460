define('modules/credits/views/credits',['require','marionette','text!./../templates/credits.hbs'],function (require) {
  "use strict";

  var Marionette = require("marionette");
    // appConfig = require("components/app-config");
  
  return Marionette.ItemView.extend({
    className: "creditsContent-module",
    template: require("text!./../templates/credits.hbs")
  });
});
