define('components/map/data-layer',['require','leaflet','jquery','underscore','class'],function (require) {
  "use strict";

  var L = require("leaflet"),
    $ = require("jquery"),
    _ = require("underscore"),
    Class = require("class");

  /**
   * @class DataLayer
   */
  var DataLayer = Class.extend({
    initialize: function() {
      this.featuresLayer = null;
      this.map = this.options.map;
      this.mapConfig = this.options.mapConfig;
      this.layerId = this.options.layerId;
      // TODO: maybe this should be provided? not this class responsibility
      $.when(this._loadLayer()).done(_.bind(this.start, this));
    },
    start: function(layerData) {
      this._layerData = layerData;
      this.show();
    },
    show: function() {
      var className = "data-layer-" + this.layerId;
     /* self = this;*/
      if (this.layerId < 8){
        this.featuresLayer = L.geoJson(this._layerData, {
          style: {"className": className}
         /* onEachFeature: function(feature, layer) {
            layer.on("mouseover", function(e) {
              $(e.layer._path).attr("class", className + "-hover " + className);
            });
            layer.on("mouseout", function(e) {
              $(e.layer._path).attr("class", className);
            });
            layer.on("click", function(e) {
              var template = "<div class='data-layer-popup'><h2>{{title}}</h2>" +
                "<span class='data-layer-text'>{{desc}}</span></div>";
              L.popup({
                  keepInView: true
                })
                .setLatLng(e.latlng)
                .setContent(
                  template.replace("{{title}}", feature.properties.title)
                    .replace("{{desc}}", feature.properties.desc))
                .openOn(self.map);
            });
          }*/
        }).addTo(this.map);
      } else if (this.layerId == 9){
        this.featuresLayer = L.tileLayer(this.mapConfig.streetsLayerURL, {
          subdomains: this.mapConfig.subdomains,
          minZoom: this.mapConfig.minZoom,
          maxZoom: this.mapConfig.maxZoom,
          zIndex: 150
        }).addTo(this.map);   
      } else {
        this.featuresLayer = L.tileLayer(this.mapConfig.oldStreetsLayerURL, {
          subdomains: this.mapConfig.subdomains,
          minZoom: this.mapConfig.minZoom,
          maxZoom: this.mapConfig.maxZoom,
          zIndex: 150
        }).addTo(this.map);
          /*,
          onEachFeature: function(feature, layer) {
            layer.on("mouseover", function(e) {
               var template = "<div class='data-layer-popup'>" +
                "<span class='data-layer-text'>{{text}}</span></div>";
              L.popup({
                  keepInView: false,
                  closeButton: false,
                  autoPan: false
                })
                .setLatLng(e.latlng)
                .setContent(
                  template.replace("{{text}}", feature.properties.Text))
                .openOn(self.map);
            });
          }
        }).addTo(this.map);*/
      }
    },
    hide: function() {
      this.map.removeLayer(this.featuresLayer);
    },
    _loadLayer: function() {
      var deferred = new $.Deferred(),
        layerURL = this.mapConfig.layersPath.replace("{id}", this.layerId),
        self = this;

      $.get(layerURL).done(function(data) {
        self.data = data;
        deferred.resolve(data);
      });
      return deferred.promise();
    }
  });

  return DataLayer;
});
