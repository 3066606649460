define('modules/map/module',['require','./controller','./router'],function (require) {
  "use strict";

  var Controller = require("./controller"),
    Router = require("./router");

  /**
   * @class MapModule
   */
  var MapModule = function (app, regionContainer) {
    app.module("map", function (MapModule) {
      this.startWithParent = false;

      this.controller = new Controller({
        app: app,
        regionContainer: regionContainer
      });

      MapModule.addInitializer(function() {
        this.controller.show();
      });

      this.router = new Router({ controller : this.controller });
    });
  };

  return MapModule;
});
