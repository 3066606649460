define('components/map/pois-layer',['require','leaflet','jquery','underscore','class','leafletLabel'],function (require) {
  "use strict";

  var L = require("leaflet"),
    $ = require("jquery"),
    _ = require("underscore"),
    Class = require("class");
    // appConfig = require("components/app-config");
    require("leafletLabel");

  /**
   * @class POIsLayer
   */
  var POIsLayer = Class.extend({
    initialize: function(options) {
      this.map = options.map;
      this.mapConfig = options.mapConfig;
      this.markersGroup = null;      
      this.featuresLayer = null;
      this.layerId = options.layerId;	
      this.POIView = this.mapConfig.POIView;
      // TODO: maybe this should be provided? not this class responsibility
      $.when(this._loadPOIs()).done(_.bind(this.start, this));
    },
    start: function(POIsData) {
      this._POIsData = POIsData;
      this.show();
    },
    show: function() {
      var markers = [],
      layers = [],
      icons = [];
      var self = this;
      var className = (this.layerId < 9) ? "data-label-" + this.layerId : "labeltext";
            
      _.each(this._POIsData, function(poi) {
        var myIcon = L.icon({
          iconUrl: (this.layerId < 9) ? "images/marker-icon-" + this.layerId + ".png" : "images/marker-icon.png",
          iconRetinaUrl: (this.layerId < 9) ? "images/marker-icon-" + this.layerId + "-2x.png" : "images/marker-icon-2x.png",
          shadowUrl: "images/marker-shadow.png",
          shadowRetinaUrl: "images/marker-shadow.png",
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          labelAnchor:[-110, 20],
          popupAnchor: [-13, -34],
          shadowSize: [41, 41]
        });
        icons.push(myIcon);
        var marker = L.marker([
            poi.coords[0],
            poi.coords[1]
        ], {
          icon: myIcon,
        }).bindLabel(poi.title, { noHide: true, className: className}),
        currentPoi = poi;

	if(poi.geoJson){
	    var layer = L.geoJson(poi.geoJson[0], {
	      style: {"className": "data-layer-7"}
	    });
	    
	    layers.push(layer);
	}

	if(self.POIView) {
	  marker.on("click", function(mark) {
	    var popup = L.popup({
		keepInView: true
	      })
	      .setLatLng(mark.latlng)
	      .setContent("<div class='poi-content'></div>")
	      .openOn(self.map),
	      poiView = new self.POIView({data: currentPoi, el: $(".poi-content", popup._container)});
	    poiView.render();
	});
	}
		
	markers.push(marker);
	
      }, this);

      this.markersGroup = L.featureGroup(markers).addTo(this.map);
      this.featuresLayer = L.featureGroup(layers).addTo(this.map);
    },
    hide: function() {
      this.map.removeLayer(this.featuresLayer);
      this.map.removeLayer(this.markersGroup);
    },
    _loadPOIs: function() {
      var deferred = new $.Deferred(),
	  labelURL = this.mapConfig.POIsPath.replace("pois-{id}", "pois-"+this.layerId),
          self = this;
      
      $.get(labelURL).done(function(data) {
        self.data = data;
        deferred.resolve(self.data);
      }).error(function(jqXHR, textStatus, errorThrown) {console.log(errorThrown);});
      return deferred.promise();
    }
  });

  return POIsLayer;
});
