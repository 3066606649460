define('modules/map/views/map',['require','marionette','components/map/city-map','underscore','components/app-config','components/bus','./poi','text!./../templates/map.hbs'],function (require) {
  "use strict";

  var Marionette = require("marionette"),
    CityMap = require("components/map/city-map"),
    _ = require("underscore"),
    appConfig = require("components/app-config"),
    Bus = require("components/bus"),
    POIView = require("./poi");

  /**
   * @class MapView
   */
  var MapView = Marionette.ItemView.extend({
    className: "quarterons-map",
    template: require("text!./../templates/map.hbs"),

    initialize: function() {
      this._mapConfig = _.extend({el: this.$el[0], POIView: POIView}, appConfig.mapDefaults);
      this._registerEvents();
    },
    onRender: function() {

    },
    onShow: function() {
      var self = this;
      setTimeout(function() {
        self._map = new CityMap({mapConfig: self._mapConfig});
        self._map.on("selectedQuaretro:change", function(quartero) {
          Bus.vent.trigger("selectedQuaretro:change", quartero);
        });
      }, 1000);
    },
    _registerEvents: function() {
      Bus.commands.setHandler("enableQuarteronsSelection", this._enableQuarteronsSelection, this);
      Bus.commands.setHandler("disableQuarteronsSelection", this._disableQuarteronsSelection, this);
      Bus.commands.setHandler("setQuarteronsOpacity", this._setQuarteronsOpacity, this);
      Bus.commands.setHandler("selectQuartero", this._selectQuartero, this);
      Bus.commands.setHandler("hideQuarterons", this._hideQuarterons, this);
      Bus.commands.setHandler("showQuarterons", this._showQuarterons, this);
      Bus.commands.setHandler("showPOIs", this._showPOIs, this);
      Bus.commands.setHandler("hidePOIs", this._hidePOIs, this);
      Bus.commands.setHandler("showDataLayer", this._showDataLayer, this);
      Bus.commands.setHandler("hideDataLayer", this._hideDataLayer, this);
      Bus.commands.setHandler("testQuartero", this._testQuartero, this);
    },
    // Deal with the map component as a result of UI interactions somewhere in
    // the app
    _hideQuarterons: function() {
      this._map.hideQuarterons();
    },
    _showQuarterons: function() {
      this._map.showQuarterons();
    },
    _showPOIs: function(layerId) {
      this._map.showPOIs(layerId);
    },
    _hidePOIs: function(layerId) {
      this._map.hidePOIs(layerId);
    },
    _showDataLayer: function(layerId) {
      this._map.showDataLayer(layerId);
    },
    _hideDataLayer: function(layerId) {
      this._map.hideDataLayer(layerId);
    },
    _enableQuarteronsSelection: function() {
      this._switchMapMode("select");
    },
    _disableQuarteronsSelection: function() {
      this._switchMapMode("browse");
    },
    _setQuarteronsOpacity: function(opacity) {
      this._map.setQuarteronsOpacity(opacity);
    },
    _selectQuartero: function(quarteroId) {
      // this._switchMapMode("selectedQuarterod", quarteroId);
      this._switchMapMode("requested", quarteroId);
    },
    _switchMapMode: function(mode, quarteroId) {
      this._mapConfig.mode = mode;
      this._mapConfig.selectedQuartero = quarteroId;
      //console.log("map switchMapMode quartero:"+quarteroId);
      if(this._map) {
        this._map.setQuarteronsMode(this._mapConfig.mode, this._mapConfig.selectedQuartero);
      }
    },
    _testQuartero: function(quartero){
      this._map.testQuartero(quartero);
    }
  });

  return MapView;
});
